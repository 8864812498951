:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: Roboto;
    --alternative-font-family: Roboto, Helvetica, Arial, sans-serif;
    --font-italic: AlegreyaSans;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 0;
    
    /* change colors for topmenu */
    --topmenu-bg: #005C69;
    --topmenu-color: white;
    --topmenu-li-a-padding: 15px 25px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #005C69;
    --link-hover-color: #000;
    --alternative-color: #005C69;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@100;300;400;500;700;800;900&display=swap';


/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#F2F2F2;
}

.darkgray {
    background-color:#222;
}

.alt-color {
    background-color:var(--alternative-color);
}

/* Header
---------------------------------------------------------*/
.topsocial p {
    color:white;
    margin:0;
}

.topsocial a svg.fa-icon {
    fill:#eee !important;
    width:16px;
    height:16px;
}

.topsocial a:hover svg.fa-icon {
    fill:white !important;
}

.topcontact {
    padding:0.5em 0;
}

.topcontact span {
    display:block;
    color:white;
    padding-right:1em;
}

.topcontact a svg.fa-icon {
    fill:white;
}

.topcontact a {
    color:white;
    text-decoration:none;
}

.topcontact a:hover {
    color:#eee;
}

.toplogo {
    padding:2em 0;
    margin-bottom:1em;
}

.contact-info {
    text-align:right;
}

.contact-info a {
    padding:0 1em;
    text-decoration:none;
    font-size:0.9rem;
}

.contact-info .contact-two {
    font-weight:bold;
    text-decoration:none;
}

.topmenu {
    position:relative;
}

.topmenu .container {
    padding:0;
    position:absolute;
    z-index:10;
    left:0;
    right:0;
    top:-20px;
    background:var(--alternative-color);
}

header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 60px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:#dcdcdc;
    font-weight:500;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:white;
    z-index:100;
    box-shadow: 0 4px 2px -2px gray;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:var(--topmenu-li-a-padding);
    color:#000;
    border-right:none;
    border-bottom:#eee solid 1px;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:var(--topmenu-bg);
    color:#b8d2e9;
}

.menu .sub-menu-items li a {
    margin:0;
}

ul.menu > .sub-menu > a:after {
content: "▾";
    position: absolute;
    right: 0px;
    top: 13px;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 0.8em;
}

ul.menu > .sub-menu > ul > .sub-menu > a:after {
content: "▸";
    position: absolute;
    right: 5px;
    top: 13px;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 0.8em;
}


.logo {
    padding:0;
}

.logo a {
    font-size:0;
}


/* bildspel
---------------------------------------------------------*/

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.homepage .slick,
.homepage .slick-slide,
.homepage .slick-substitute > div {

    height: 600px;
}

.homepage.slick-slide,
.homepage .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.homepage .slick-slide,
.homepage .slick-substitute > div {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
} 

.homepage .slick-title {
    max-width:1100px;
    width:100%;
    margin:0 auto;
    color:white;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.homepage .title-inner {
    max-width:680px;
    width:100%;
}

.homepage .slick-title h2 {
    display:block;
    font-size: calc(1.5 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin:0;
    line-height:normal;

}

.homepage .offert {
    margin-top:1em;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Content-startpage
---------------------------------------------------------*/
.block-2  {
    padding:2em 0;
}

.block-2 >.container {
    border-bottom:#EBEBEB solid 1px;
}

.contact-box .inner-content {
    padding:1.6em;
}

.flex.layout {
    align-items:flex-start;
}

.contact-box {
    color:white;
}

.contact-box h3 {
    display:block;
    width:100%;
    border-bottom:#fff solid 1px;
    color:white;
}

.contact-box a {
    color:white;
    text-decoration:none;
}

/* zoomboxar */

.boxwrapper {
    min-height: 25vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
    margin-bottom:1em;
}

.boxwrapper h2 span {
    display:block;
    text-transform:none;
    background:#1A1D1D;
    font-weight:300;
    letter-spacing:0;
    font-size:0.9rem;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: #005C69;
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index:101;
    opacity:0;
    text-align:center;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    color:white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}


/* block-3 */
.block-3 {
    padding:1.5em 0;
}

.reco {
    padding:2em 0;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/
.top-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 30vh;
    overflow: visible;
}


.content-page>.flex {
    align-items:flex-start;
}

.content-page .content-inner {
    padding:1em;
}

.art-content p:first-of-type {
    margin-top:0;
}

.art-form .content-inner {
    padding:1em;
    color:#000;
}

.art-form input[type="email"], .art-form input[type="number"], .art-form input[type="search"], .art-form input[type="text"], .art-form input[type="tel"], .art-form input[type="url"], .art-form input[type="password"], .art-form textarea {
    background:transparent;
    border-radius:0;
    border-top:0;
    border-left:0;
    border-right:0;
    border-bottom:#222 solid 1px;
}

.art-form ::-webkit-input-placeholder {
  color: #222;
}

.art-form button[type=submit] {
    border:#222 solid 1px;
    display:block;
    width:100%;
}

.contact-text {
    font-family:var(--font-italic);
    display:block;
    font-style:italic;
    font-size:1.3rem;
    padding-right:1em;
    padding-bottom:1.5em;
}

table, tbody {
    width:100%;
    margin-top:0;
}

table td p {
    margin:0;
}

table td {
    border-color:#cecece;
    padding:0.4em;
}

tbody tr:nth-child(even) {
   background-color: #eee;
}

.dots ul {
    list-style:none;
    margin:0;
    padding:0;
}

.dots ul li {
  padding-left: 1.7em;
    margin:0.3rem 0;
    font-style:italic;
}

.dots ul li:before {
    color:white;
    content: "\2714"; 
    display: inline-block;
    width: 1.7em; /* same as padding-left set on li */
    margin-left: -1.7em; /* same as padding-left set on li */
    margin-right:1em;
    text-align:center;
    font-style:normal;
}

/* partners
---------------------------------------------------------*/
.partners {
    border-top:#ddd solid 1px;
}

.partners ul {
    list-style:none;
    margin:0;
    padding:0;
}

.partners ul li {
    background:white;
}

.partners ul li img {
    display:block;
    background:white;
}

.partners ul li img:hover {
    opacity:0.7;
}



/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

figure {
    margin:0;
}

iframe {
    max-width: 100%;
    width: 100%;
    height: 30vh;
    border: none;
}

    

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer h4 {
    color:white;
    font-size:1.3rem;
    letter-spacing:0;
}

footer a {
    color:white;
    text-decoration:none;
}

footer svg.fa-icon {
    fill:white;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}





/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height: 120px;
}

.boltform br {
    display:none;
}

.boltform label {
    display:none;
}

input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=file], input[type=month], input[type=number], input[type=password], input[type=phone], input[type=range], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], select, textarea {
    width: 100%;
    color: #222;
    border-radius: 0;
    padding: 1em;
}

button[type=submit], input[type=submit], .button {
    margin: 0;
    background: var(--link-color);
    border: var(--link-color) solid 1px;
    color: white;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover {
    background: var(--link-hover-color);
    border: var(--link-hover-color) solid 1px;
    color: white;
}


input[type=color], input[type=date], input[type=datetime-local], input[type=datetime], input[type=email], input[type=file], input[type=month], input[type=number], input[type=password], input[type=phone], input[type=range], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], select, textarea {
    margin-bottom:0;
}



img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}

/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
/*.flex > * > img:not([class]) {
    width: 100%;
}*/

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

/* 
.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}


/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background: #005C69 !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border-color:white;
    }
    .homepage .slick-title {
        padding:0 1em;
    }
    .homepage .slick-title h2 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    }
   
}

@media (max-width: var(--breakpoint-s)) {
    .contact-padding {
        display:block;
        padding: 0.4rem 0;
    }
    footer a {
        display:block;
        padding: 0.4rem 0;
    }
}

@media (max-width: var(--breakpoint-xs)) {
    footer .container {
        text-align:center;
    }
    .top-img {
        min-height:20vh;
        background-position:center center;
    }
    h1 {
            font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }
    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}
